import {initStore} from '@distru/web/features/product_menu/parent/store/utils';

// NOTE:
// As the script tag executing this is using `async`, we need to
// ensure that the initialization is run correctly no matter if
// the script is loaded before the DOM is ready or after.
// https://developer.mozilla.org/en-US/docs/Web/API/Document/DOMContentLoaded_event#checking_whether_loading_is_already_complete
//
// For more context on `async`, you can read the comment in `createIframeCode`.
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', () => {
    initStore();
  });
} else {
  initStore();
}
