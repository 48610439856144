import {
  ProductMenuParentStore,
  createUseStore,
} from '@distru/web/features/product_menu/parent/store/productMenuParentStore';

export const initStore = () => {
  const store = createUseStore();
  store.getState().init();
  return store;
};

export const shutdownStore = (store: ProductMenuParentStore) => {
  store.getState().shutdown();
};
