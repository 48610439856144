import {z} from 'zod';

const SaveScrollSchema = z.object({
  path: z.string(),
  type: z.literal('SAVE_SCROLL'),
});

const RestoreScrollSchema = z.object({
  path: z.string(),
  type: z.literal('RESTORE_SCROLL'),
});

export const ParentMessageSchema = z.union([
  SaveScrollSchema,
  RestoreScrollSchema,
]);
