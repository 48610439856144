import {IFrameObject, iframeResizer} from 'iframe-resizer';
import {isDefined} from 'remeda';
import {create} from 'zustand';

import {PRODUCT_MENU_IFRAME_ID} from '@distru/web/features/product_menu/config';
import {IFrameMessage} from '@distru/web/features/product_menu/iframe/old/store/types';
import {isParentMessage} from '@distru/web/features/product_menu/parent/store/typeGuards';
import {assertUnreachableCase} from '@distru/web/features/typing/utils';
import {isEqual, update} from '@distru/web/functional_utils';

// === TYPES ===

interface ScrollCoordinates {
  x: number;
  y: number;
}

interface Data {
  iFrameObject: IFrameObject | undefined;
  scrollCoordinatesByPath: Record<string, ScrollCoordinates>;
  scrollListener: (() => void) | undefined;
}

interface Actions {
  init: () => void;
  shutdown: () => void;
}

type ProductMenuParentState = Data & Actions;
export type ProductMenuParentStore = ReturnType<typeof createUseStore>;
type StateSet = (
  partial:
    | ProductMenuParentState
    | Partial<ProductMenuParentState>
    | ((
        state: ProductMenuParentState
      ) => ProductMenuParentState | Partial<ProductMenuParentState>),
  replace?: boolean | undefined
) => void;
type StateGet = () => ProductMenuParentState;
type StateFetch = <Key extends keyof Data>(
  key: Key
) => NonNullable<ProductMenuParentState[Key]>;

interface StateFns {
  fetch: StateFetch;
  get: StateGet;
  set: StateSet;
}

// === STORE ===

/**
 * Creates Zustand store that controls the logic of the product menu
 * that needs to be run by the parent (the page that embeds the iframe).
 *
 * So far, this logic is:
 * 1. Run the initialization logic for the iframe-resizer library that needs to run on the parent
 * 2. Setup a scroll listener (used to tell the iframe when we have reached the infinite scroll threshold)
 *    - Here's a Loom that proves that the scroll listener needs to be set up on the parent: https://www.loom.com/share/be60aa58a9df4e52af8043bb1383f2b9?sid=5f67e7dc-2806-47e6-aecb-401194e121b0
 *
 * Keep in mind that this logic is run by the parent page, which might be controlled by a customer
 * and, therefore, has no access to any of our configuration and providers (aka, you
 * cannot access our env variables or our Redux store from this store).
 *
 * Also, notice that, even if the parent and the iframe could technically use the same Zustand store,
 * doing that would be pretty confusing, as state modified on that store by one of them (either the parent
 * or the iframe) wouldn't be visible by the other. Given that, it is better to have two separate stores
 * and make the parent and the iframe only use their own store.
 * When we need to communicate between the parent and the iframe, we will make their respective stores
 * send messages between them.
 * Here's a Loom that proves that state modified by either the parent or the iframe isn't visible by the other:
 * https://www.loom.com/share/5ef5df1b0f424938a7dac39e44e6e169?sid=b6a293c6-a509-491e-9a15-93cbbdb48ce9
 *
 * NOTE:
 * You can find the Zustand store for the iframe at:
 * js-packages/web/features/product_menu/iframe/store/productMenuIFrameStore.ts
 */
export const createUseStore = () =>
  create<ProductMenuParentState>()((set, get) => {
    const fetch: StateFetch = (key) => {
      const value = get()[key];
      if (!isDefined(value)) {
        throw new Error(`Expected ${key} to exist`);
      }
      return value;
    };
    const s: StateFns = {fetch, get, set};
    const initialData: Data = {
      iFrameObject: undefined,
      scrollCoordinatesByPath: {},
      scrollListener: undefined,
    };
    const actions: Actions = {
      init: () => {
        setupIFrame(s);
        setupScrollListener(s);
      },
      shutdown: () => {
        try {
          removeScrollListener(s);
          closeIFrame(s);
        } catch (e) {
          // NOTE:
          // In case any step in the shutdown fails,
          // we don't want to crash
        }
      },
    };
    return {...initialData, ...actions};
  });

// === STORE HELPERS ===

const setupIFrame = (s: StateFns) => {
  const iframe = document.getElementById(PRODUCT_MENU_IFRAME_ID);
  if (!iframe) {
    throw new Error(
      'Distru menu iframe not found. Please ensure you have properly copied and pasted the iframe code from the Distru app, and that you only have 1 menu embedded per page.'
    );
  }

  iframe.onload = () => {
    const iFrameComponent = iframeResizer(
      {
        log: false,
        onMessage: ({message}) => {
          if (!isParentMessage(message)) {
            throw new Error('Invalid message received from iframe');
          }
          switch (message.type) {
            case 'SAVE_SCROLL': {
              s.set({
                scrollCoordinatesByPath: update(
                  s.get().scrollCoordinatesByPath,
                  {
                    [message.path]: {x: window.scrollX, y: window.scrollY},
                  }
                ),
              });
              break;
            }

            case 'RESTORE_SCROLL': {
              const scrollCoordinates = s.get().scrollCoordinatesByPath[
                message.path
              ];
              if (scrollCoordinates) {
                const initialScrollCoordinates = {
                  x: window.scrollX,
                  y: window.scrollY,
                };
                restoreScrollCoordinates(
                  scrollCoordinates,
                  initialScrollCoordinates
                );
              }
              break;
            }

            default:
              return assertUnreachableCase(message);
          }
        },
      },
      `#${PRODUCT_MENU_IFRAME_ID}`
    )[0];
    if (!iFrameComponent) {
      throw new Error('Failed to initialize iframe-resizer');
    }
    s.set({iFrameObject: iFrameComponent.iFrameResizer});
  };
};

const closeIFrame = (s: StateFns) => {
  s.fetch('iFrameObject').close();
};

// === SCROLLING ===

const setupScrollListener = (s: StateFns) => {
  removeScrollListener(s);
  const scrollListener = () => {
    if (isAtInfiniteScrollThreshold()) {
      sendMessageToIFrame({type: 'INFINITE_SCROLL_THRESHOLD_REACHED'}, s);
    }
  };
  window.addEventListener('scroll', scrollListener);
  s.set({scrollListener});
};

const removeScrollListener = (s: StateFns) => {
  const {scrollListener} = s.get();
  if (scrollListener) {
    window.removeEventListener('scroll', scrollListener);
  }
};

const isAtInfiniteScrollThreshold = () => {
  const {body, documentElement: html} = document;
  const {innerHeight} = window;
  const height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
  const bottom = height - innerHeight;
  return window.scrollY !== undefined && window.scrollY >= bottom - innerHeight;
};

const restoreScrollCoordinates = (
  scrollCoordinates: ScrollCoordinates,
  initialScrollCoordinates: ScrollCoordinates,
  retriesLeft = 500
) => {
  const hasScrollChanged = () =>
    window.scrollX !== initialScrollCoordinates.x ||
    window.scrollY !== initialScrollCoordinates.y;
  const isScrollNecessary = !isEqual(
    scrollCoordinates,
    initialScrollCoordinates
  );
  // Only restore scroll, if:
  // 1. The scroll position hasn't already changed.
  //    If it has changed, we assume it was changed by the user,
  //    so we don't want to then programmatically change the scroll
  //    as that could be surprising/annoying to the user.
  // 2. The scroll coordinates to restore are different than the initial ones,
  //    as then there's no need to restore the scroll
  // 3. We still have retries left. If we don't manage to restore the
  //    scroll coordinates after the specified amount of retries, just
  //    give up
  if (!hasScrollChanged() && isScrollNecessary && retriesLeft) {
    window.scrollTo(scrollCoordinates.x, scrollCoordinates.y);
    // If `scrollTo` was unable to scroll (e.g.: because the component
    // wasn't rendered yet), we'll retry after a short delay.
    if (!hasScrollChanged()) {
      setTimeout(() => {
        restoreScrollCoordinates(
          scrollCoordinates,
          initialScrollCoordinates,
          retriesLeft - 1
        );
      }, 10);
    }
  }
};

// === PARENT -> IFRAME COMMUNICATION ===

const sendMessageToIFrame = (message: IFrameMessage, s: StateFns) => {
  s.get().iFrameObject?.sendMessage(message, '*');
};
