import {ViewMode} from '@distru/web/features/product_menu/types';

export const PRODUCT_MENU_IFRAME_ID = 'distru-product-menu-iframe-id';
export const PRODUCT_MENU_SESSION_KEY = 'phoenixMenuAuthToken';
export const DEFAULT_VIEW_MODE: ViewMode = 'grid';
export const INVITE_CODE_EXPIRATION_DAYS = 7;
export const VISIBILITY_LEVELS = {
  ALL: 2,
  NONE: 0,
  SELECT: 1,
};

const config = {
  SELECT_OPTIONS: [
    'Do Not Include In Menus',
    'Include In Select Menus',
    'Include In All Menus',
  ],
  SETTINGS_CATEGORY_FILTER_TITLE: 'settingsCategoryFilterTitle',
  SETTINGS_DEFAULT_ORDER_STATUS: 'settingsDefaultOrderStatus',
  SETTINGS_ENABLE_CASE_ORDERS: 'settingsEnableCaseOrders',
  SETTINGS_ENABLE_CATEGORY_FILTER: 'settingsEnableCategoryFilter',
  SETTINGS_ENABLE_CUSTOM_CHECKOUT: 'settingsEnableCustomCheckout',
  SETTINGS_ENABLE_CUSTOM_CHECKOUT_TEXT: 'settingsEnableCustomCheckoutText',
  SETTINGS_ENABLE_GROUP_FILTER: 'settingsEnableGroupFilter',
  SETTINGS_ENABLE_GUEST_CART: 'settingsEnableGuestCart',
  SETTINGS_ENABLE_MINIMUM_ORDER_VALUE: 'settingsEnableMinimumOrderValue',
  SETTINGS_ENABLE_SALES_REP_LOGIN: 'settingsEnableSalesRepLogin',
  SETTINGS_ENABLE_SUBCATEGORY_FILTER: 'settingsEnableSubcategoryFilter',
  SETTINGS_ENABLE_VENDOR_FILTER: 'settingsEnableVendorFilter',
  SETTINGS_EXPOSE_INVENTORY_LEVELS: 'settingsExposeInventoryLevels',
  SETTINGS_EXPOSE_PRICES: 'settingsExposePrices',
  SETTINGS_EXPOSE_VENDOR: 'settingsExposeVendor',
  SETTINGS_GROUP_FILTER_TITLE: 'settingsGroupFilterTitle',
  SETTINGS_MINIMUM_ORDER_VALUE: 'settingsMinimumOrderValue',
  SETTINGS_SUBCATEGORY_FILTER_TITLE: 'settingsSubcategoryFilterTitle',
  SETTINGS_VENDOR_FILTER_TITLE: 'settingsVendorFilterTitle',
  UNIT_TYPE_CASE_NAME: 'Case',
  VISIBILITY_LEVELS,
} as const;

export default config;
